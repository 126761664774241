import { render, staticRenderFns } from "./NowNav.vue?vue&type=template&id=14db40ec&scoped=true&"
import script from "./NowNav.vue?vue&type=script&lang=js&"
export * from "./NowNav.vue?vue&type=script&lang=js&"
import style0 from "./NowNav.vue?vue&type=style&index=0&id=14db40ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14db40ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowNavSubscription: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowNavSubscription.vue').default,NowNavNotification: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowNavNotification.vue').default,NowNavAccount: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowNavAccount.vue').default,NowIcon: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowIcon.vue').default})
