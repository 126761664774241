import { render, staticRenderFns } from "./NowNavNotificationMenu.vue?vue&type=template&id=292d296d&scoped=true&"
import script from "./NowNavNotificationMenu.vue?vue&type=script&lang=js&"
export * from "./NowNavNotificationMenu.vue?vue&type=script&lang=js&"
import style0 from "./NowNavNotificationMenu.vue?vue&type=style&index=0&id=292d296d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "292d296d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowButton: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowButton.vue').default,NowIcon: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowIcon.vue').default,NowNavNotificationMenuItem: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowNavNotificationMenuItem.vue').default})
