import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4129d5ad = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _165df88c = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _bcb093fc = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _6dd40b1b = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _6e975c7e = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _0b0eaec1 = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _4d3bc529 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _565a8bc6 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _c0d7a140 = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _62b800b1 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _6e355709 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _551bfc0e = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _68d0aa0a = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _124a2ca5 = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _6bcbb97e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _081c5d16 = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _0a64855a = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _61ed8bdb = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _4f8c7d1a = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _b03569e2 = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _69c7470a = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _345c8990 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _42bc6bb5 = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _464030f3 = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _aac6af1c = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _1e04fc12 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _6a44be72 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _7c09e1ad = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _5414f834 = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _0eb25fe0 = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _007d4094 = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _9e844ab2 = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _4074af17 = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _c8957c1e = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _8b1e48ea = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _e944ac2e = () => interopDefault(import('../pages/unit/category/index.vue' /* webpackChunkName: "pages/unit/category/index" */))
const _75a02512 = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _772c2086 = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _459ca42c = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _2cfefda8 = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _53d1aff5 = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _06676345 = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _034d7b05 = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _3c9ad52f = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _b2ecef0a = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _092d100f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _4129d5ad,
    name: "account"
  }, {
    path: "/action",
    component: _165df88c,
    name: "action"
  }, {
    path: "/archive",
    component: _bcb093fc,
    name: "archive"
  }, {
    path: "/audit",
    component: _6dd40b1b,
    name: "audit"
  }, {
    path: "/deviation",
    component: _6e975c7e,
    name: "deviation"
  }, {
    path: "/it",
    component: _0b0eaec1,
    name: "it"
  }, {
    path: "/login",
    component: _4d3bc529,
    name: "login"
  }, {
    path: "/logout",
    component: _565a8bc6,
    name: "logout"
  }, {
    path: "/offline",
    component: _c0d7a140,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _62b800b1,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _6e355709,
    name: "organization"
  }, {
    path: "/register",
    component: _551bfc0e,
    name: "register"
  }, {
    path: "/report",
    component: _68d0aa0a,
    name: "report"
  }, {
    path: "/risk",
    component: _124a2ca5,
    name: "risk"
  }, {
    path: "/search",
    component: _6bcbb97e,
    name: "search"
  }, {
    path: "/styles",
    component: _081c5d16,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _0a64855a,
    name: "suggestion"
  }, {
    path: "/task",
    component: _61ed8bdb,
    name: "task"
  }, {
    path: "/unit",
    component: _4f8c7d1a,
    name: "unit"
  }, {
    path: "/verify",
    component: _b03569e2,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _69c7470a,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _345c8990,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _42bc6bb5,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _464030f3,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _aac6af1c,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _1e04fc12,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _6a44be72,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _7c09e1ad,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _5414f834,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _0eb25fe0,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _007d4094,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _9e844ab2,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _4074af17,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _c8957c1e,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _8b1e48ea,
    name: "task-group"
  }, {
    path: "/unit/category",
    component: _e944ac2e,
    name: "unit-category"
  }, {
    path: "/action/:id",
    component: _75a02512,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _772c2086,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _459ca42c,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _2cfefda8,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _53d1aff5,
    name: "report-id"
  }, {
    path: "/suggestion/:id",
    component: _06676345,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _034d7b05,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _3c9ad52f,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _b2ecef0a,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _092d100f,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
