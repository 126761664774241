import { render, staticRenderFns } from "./NowNavAccount.vue?vue&type=template&id=78ce813a&scoped=true&"
import script from "./NowNavAccount.vue?vue&type=script&lang=js&"
export * from "./NowNavAccount.vue?vue&type=script&lang=js&"
import style0 from "./NowNavAccount.vue?vue&type=style&index=0&id=78ce813a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78ce813a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowBadge: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowBadge.vue').default,NowIcon: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowIcon.vue').default,NowNavAccountMenu: require('/home/forge/dev.isoudvikling.com/releases/20241212133852/components/NowNavAccountMenu.vue').default})
